import React from 'react';

const CognitiveIcon = () => {
    return(
        <>
        <span className="cognitive-icon">C</span>
        </>
    )
}

export default CognitiveIcon;