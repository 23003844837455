import React from 'react';

const SensoryIcon = () => {
    return(
        <>
        <span className="sensory-icon">S</span>
        </>
    )
}

export default SensoryIcon;