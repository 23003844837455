import React from 'react';

const PhysicalIcon = () => {
    return(
        <>
        <span className="physical-icon">P</span>
        </>
    )
}

export default PhysicalIcon;